@import url('https://fonts.googleapis.com/css?family=Fredericka+the+Great|Nunito:400,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');

$title-font-family: 'Roboto', serif;
$intermediate-font-family: 'Roboto', sans-serif;
$general-font-family: 'Roboto', sans-serif;
$code-font-family: 'Roboto', monospace;
$normal-weight: 400;
$bold-weight: 700;

$primary-color: #3F3D56;
$secondary-color: #50413c;
$background-color: white;
$base-font-color: #3F3D56;
