@import 'variables';

#scrolly-teller-app {
  max-width: 2000px;
  margin: 0 auto;
}

#app {
  width: 98%;
  margin: 0 1%;
  background-color: $background-color;
}

/*  ------------- TITLE/NAVBAR -------------  */


/*  ------------- TITLE -------------  */
.title {
  margin: 0.5rem;
  //padding: 0.5rem;
  @extend h1;
} // end title

.intro {
  margin: 0.5rem;
  padding: 0.5rem;
}

.left {
  margin: 0;
  text-align: left;
  width: 70%;
}

/*  ------------- NAVIGATION -------------  */
.navbar {
  position: sticky;
  top: 10vh;
  left: 0;
  z-index: 1;
  //padding: 16px;
  height: auto;
  max-height: 170px;
}

.navbar .tabs{
  margin-left: 5%;
  width: fit-content;
  display: flex;
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 0 1px 0 rgba(#ffffff, 0.15), 0 6px 12px 0 rgba(#ffffff, 0.15);

  padding: 6px;
  border-radius: 99px; // just a high number to create pill effect
  * {
    z-index: 2;
  }
}
.navButton {
  //background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 128px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 99px; // just a high number to create pill effect
  cursor: pointer;
  transition: color 0.15s ease-in;
}

.navButton h3 {
  pointer-events: none;
  text-align: end;
  font-size: 1.1rem;
}

.navButton.active {
  //background-color: #04AA6D;
  //color: white;
  background-color: #ffffff;
}

.navButton.active .box{
  width: 32px;
  height: 32px;
  background-color: #ffffff;
}

.dashed {
  border: black;
  border-style: dashed;
  border-width: medium;
}

.narration .link {
  font-size: 1.2rem;
  line-height: 1.3rem;
}

// to differentiate trigger JSON from other code
.narration-content p pre.trigger {
  text-align: left;
  padding: 2%;
  border: darkgray;
  background: #e4effd;
  border-width: thin;
  border-style: solid;
  border-radius: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.centered-narration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 80vw;
  height: 100vh;
}

.centered-narration .narration-content {
  z-index: 1000;
}


