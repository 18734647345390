svg {
  overflow: hidden;
}

.axis {
  text {
    fill: $base-font-color;
    stroke: none;
    font-family: $title-font-family;
  }

  .tick {
    fill: none;
    stroke: $base-font-color;
  }

  path {
    fill: none;
    stroke: $base-font-color;
  }

}

.inner-axis {
  text {
    fill: none;
    stroke: none;
  }

  .tick {
    fill: none;
    stroke: $secondary-color;
    stroke-opacity: .1;
  }

  path {
    fill: none;
    stroke: none;
  }
}

.y-title, .x-title {
  color: $secondary-color;
  font-size: 0.2em;
  font-weight: 200;
  line-height: 20px;
  letter-spacing: -1px;
  margin-bottom: 5%;
}

.title {
}


.line {
  fill: none;
  stroke: blue;
  stroke-width: 2px;
}

.dashed {
  fill: none;
  stroke: $secondary-color;
  stroke-width: 4px;
  stroke-dasharray: 4 4;
}
