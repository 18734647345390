.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.narration p {
  padding: 0 !important;
  text-align: left !important;
}

.narration h2 {
  padding: 0 0 1rem 0 !important;
}

.tooltip {
  position: absolute;
  padding: 8px;
  background: white;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}

.tooltip_title {
  font-size: 18px;
  font-weight: bold;
}

.tooltip_des_title {
  font-size: 14px;
  font-weight: bold;
}

.tooltip_des {
  font-size: 14px;
}

.w3-badge {
  border-radius: 45%;
}
.w3-badge, .w3-tag {
  margin-left: 8px;
  margin-right: 8px;
  font-size: 14px;
  font-weight: normal;
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.section {
  max-width: 85vw;
  margin: 60px auto;
}

.narration_list {
  padding: 0 !important;
  min-width: 25% !important;
  max-width: 15rem !important;
}

.narration-content h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2em;
}

.narration-content p {
  font-size: 16px;
  line-height: 23px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .section {

  }

}

#intro{
  height: calc(100vh - 60px - 60px);
  margin: 60px auto;
}

#intro .title{
  color: #000000;
  font-weight: bold;
  font-size: 2rem;
}

#intro .subTitle{
  margin: 0.5rem;
  color: #bbbbbb;
}

.intro_img {
  position: absolute;
  height: 50vh;
  top: 0;
  right: 0;
  z-index: -1;
}

.intro_scroll{

  width: fit-content;
  color: black;
  padding-top: 48px !important;
  padding: 16px;
  margin: auto;
  font-weight: 600;


}

.intro_scroll_img {
  width: 36px;
  margin: auto;
  display: block;
}

.content {
  max-width: 85vw;
  margin: auto;
  padding-top: 32px;
  padding-bottom: 32px;
  color: black;
  text-align: center;
}

.footer-content {
  font-size: 1rem;
  margin: auto;
  padding-top: 16px;
  padding-bottom: 16px;
  color: black;
  text-align: center;
}

.infoBar {
  border-bottom: 1px solid black;
}

.infoBarContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 32px;
}

.infoBarSession{
  display: flex;
  flex-basis: 50%;

}

.infoBarContent .infoBarSession p{
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
}

.infoBarSessionContent{
  margin-left: 16px;
  margin-right: 16px;
}

.infoBarSessionContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.infoBarDetail.geoLoc{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 33%;
  justify-content: center;
}

.infoBarDetail.valuation{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 33%;
  justify-content: center;
  margin: auto;
}

.infoBarContent .infoBarSession .infoBarDetail p{
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
}

.infoBarContent .infoBarSession .infoBarDetail .continentCircle{
  width: 2rem;
  height: 2rem;
  border-radius: 100px;
}

.graph_container{
  height: 95vh !important;
  top: 5vh !important;
}